import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { DatePipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  InputSignal,
  ModelSignal,
  OutputEmitterRef,
  WritableSignal,
  input,
  model,
  output,
  signal,
} from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
// eslint-disable-next-line no-restricted-imports
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

@Component({
  selector: 'mp-facet-date-selector',
  standalone: true,
  templateUrl: './facet-date-selector.component.html',
  styleUrl: './facet-date-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, DatePipe, A11yModule, MatDatepickerModule, MatButtonModule, MatIconModule, OverlayModule],
})
export class FacetDateSelectorComponent {
  @HostBinding() readonly class = 'mp-facet-date-selector';

  readonly selectedDate: ModelSignal<string | undefined> = model.required<string | undefined>();

  readonly isDisabled: InputSignal<boolean> = input<boolean>(false);

  readonly label: InputSignal<string> = input<string>('von');

  readonly selectedDateChange: OutputEmitterRef<string | undefined> = output<string | undefined>();

  protected readonly isOpen: WritableSignal<boolean> = signal<boolean>(false);

  onSelectedDateChange(selectedDate: Date): void {
    this.isOpen.set(false);
    this.selectedDateChange.emit(selectedDate.toISOString());
  }

  onSelectedDateClear(): void {
    this.selectedDate.set(undefined);
    this.selectedDateChange.emit(this.selectedDate());
  }

  toggleCalendarVisibility(): void {
    this.isOpen.set(!this.isOpen());
  }
}
