import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  InputSignal,
  ModelSignal,
  OutputEmitterRef,
  Signal,
  WritableSignal,
  computed,
  input,
  model,
  output,
  signal,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
// eslint-disable-next-line no-restricted-imports
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

import { FilterBadgeComponent, FilterSearchFieldComponent } from '@core/ui';
import { AttributeFacet } from '@mp/shared/facets/domain';
import { filterBuckets } from '@mp/shared/facets/util';

import { AttributeFacetBucketDirective } from '../attribute-facet-bucket.directive';

@Component({
  selector: 'mp-attribute-facet-buckets-group',
  standalone: true,
  templateUrl: './attribute-facet-buckets-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgTemplateOutlet, MatIconModule, MatLegacyButtonModule, FilterBadgeComponent, FilterSearchFieldComponent],
})
export class AttributeFacetBucketsGroupComponent {
  readonly bucketsGroup: InputSignal<AttributeFacet.BucketLevel0> = input.required<AttributeFacet.BucketLevel0>();

  readonly showSelectionInfo: InputSignal<boolean> = input.required<boolean>();

  readonly searchable: InputSignal<boolean> = input.required<boolean>();

  readonly searchFieldPlaceholder: InputSignal<string> = input<string>('');

  readonly isSearchExpanded: ModelSignal<boolean> = model.required<boolean>();

  readonly isExpanded: ModelSignal<boolean> = model.required<boolean>();

  readonly clearSelection: OutputEmitterRef<void> = output<void>();

  readonly showMoreClick: OutputEmitterRef<void> = output<void>();

  readonly searchTermChange: OutputEmitterRef<string> = output<string>();

  protected readonly searchTerm: WritableSignal<string> = signal<string>('');

  protected readonly bucketSelectedOptionsQuantity: Signal<number | null> = computed(() =>
    this.getBucketSelectedOptionsQuantity(),
  );

  protected readonly filteredBuckets: Signal<readonly AttributeFacet.BucketLevel1<unknown>[]> = computed(() => {
    if (!this.searchable() || !this.searchTerm()) {
      return this.bucketsGroup().children;
    }

    return filterBuckets(this.bucketsGroup().children, this.searchTerm());
  });

  /**
   * The ref to the bucket template.
   */
  @ContentChild(AttributeFacetBucketDirective)
  readonly bucketDirective!: AttributeFacetBucketDirective<unknown>;

  toggleExpanded(): void {
    this.isExpanded.set(!this.isExpanded());
  }

  toggleSearchExpanded(): void {
    if (this.isSearchExpanded()) {
      // Clear search term when search field is collapsed
      this.searchTerm.set('');
    }
    this.isSearchExpanded.set(!this.isSearchExpanded());
  }

  onClearSelection(): void {
    this.searchTerm.set('');
    this.clearSelection.emit();
  }

  onShowMore(): void {
    this.showMoreClick.emit();
  }

  onSearchTermChange(searchTerm: string): void {
    this.searchTerm.set(searchTerm);

    this.searchTermChange.emit(searchTerm);
  }

  private getBucketSelectedOptionsQuantity(): number | null {
    const bucketSelectedItemsCount = this.bucketsGroup().children.filter((item) => item.selected).length;
    return bucketSelectedItemsCount > 0 ? bucketSelectedItemsCount : null;
  }
}
