import { BooleanInput, NumberInput, coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { DecimalPipe, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Optional,
  Output,
  TemplateRef,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
// eslint-disable-next-line no-restricted-imports
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';

import { FilterBadgeComponent } from '@core/ui';
import { InsightsBaseEvent, InsightsEvent, insightsNamedEvent } from '@mp/shared/app-insights/domain';
import { InsightsEventsTrackingService } from '@mp/shared/app-insights/util';
import { MultiSelectFacet } from '@mp/shared/facets/domain';
import { FacetSelectionService } from '@mp/shared/facets/util';

import { ListBaseFacetComponent } from '../list-base-facet/list-base-facet.component';
import { ListFacetBucketDirective } from '../list-base-facet/list-facet-bucket.directive';

let defaultSelectionKeyCounter = 0;

/**
 * The context of the bucket template.
 */
export interface MultiSelectFacetBucketContext<TData extends object | unknown = unknown> {
  /**
   * The bucket object.
   */
  $implicit: MultiSelectFacet.Bucket<TData>;
  /**
   * The bucket object.
   */
  bucket: MultiSelectFacet.Bucket<TData>;
}

export interface MultiSelectFacetInsightsEventsConfig {
  filterExpand: InsightsEvent;
  filterCollapse: InsightsEvent;
  valueSelect: InsightsEvent;
  filterReset: InsightsEvent;
  filterSearch: InsightsEvent;
}

@Component({
  selector: 'mp-multi-select-facet',
  standalone: true,
  templateUrl: './multi-select-facet.component.html',
  styleUrl: './multi-select-facet.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgTemplateOutlet,
    DecimalPipe,

    MatLegacyButtonModule,
    MatIconModule,
    MatLegacyCheckboxModule,

    ListBaseFacetComponent,
    ListFacetBucketDirective,
    FilterBadgeComponent,
  ],
})
export class MultiSelectFacetComponent<TData extends object | unknown = unknown> {
  private _facet!: MultiSelectFacet<TData>;
  private _searchable = false;
  private _selectionKey = `multeSelectFacet${++defaultSelectionKeyCounter}`;
  private _maxVisibleItems?: number | null;
  private _showSelectionInfo = false;
  private _filterName = '';
  private _insightsEventsConfig: MultiSelectFacetInsightsEventsConfig | undefined = undefined;

  constructor(
    private readonly selectionService: FacetSelectionService,
    @Optional() private readonly insightsEventsTrackingService: InsightsEventsTrackingService | null,
  ) {}

  /**
   * The MultiSelectFacet object to display.
   */
  get facet(): MultiSelectFacet<TData> {
    return this._facet;
  }

  @Input()
  set facet(value: MultiSelectFacet<TData>) {
    this._facet = value;
    this.selectionCount = value.buckets.reduce((r, b) => r + +b.selected, 0);
  }

  /**
   * Specifies the name of the filter.
   */
  @Input()
  set filterName(value: string) {
    this._filterName = value;
    this._insightsEventsConfig = this.getFilterInsightsEventsConfig(value);
  }

  get filterName() {
    return this._filterName;
  }

  /**
   * Specifies the icon of the filter component.
   */
  @Input()
  icon?: string;

  /**
   * If the current selection state should be stored, provide a unique key within the current instance
   * of `FacetSelectionService`. This is a constant value and must not be changed.
   */
  get selectionKey() {
    return this._selectionKey;
  }

  @Input()
  set selectionKey(value: string | undefined) {
    this._selectionKey = value ?? `multeSelectFacet${++defaultSelectionKeyCounter}`;
  }

  /**
   * Specifies whether a badge with the number of selected values should be shown if a feature is collapsed.
   */
  get showSelectionInfo() {
    return this._showSelectionInfo;
  }

  @Input()
  set showSelectionInfo(value: BooleanInput) {
    this._showSelectionInfo = coerceBooleanProperty(value);
  }

  /**
   * Specifies whether a search field is shown to search the buckets.
   */
  get searchable() {
    return this._searchable;
  }

  @Input()
  set searchable(value: BooleanInput) {
    this._searchable = coerceBooleanProperty(value);
  }

  /**
   * The placeholder of the search field.
   */
  @Input()
  searchFieldPlaceholder = 'Merkmale durchsuchen';

  /**
   * Sets the number of items visible when not expanded.
   */
  @Input()
  get maxVisibleItems() {
    return this._maxVisibleItems;
  }

  set maxVisibleItems(value: NumberInput) {
    this._maxVisibleItems = coerceNumberProperty(value, null);
  }

  @Input() trackInsightsEvents = false;

  /**
   * The template to use for a bucket.
   */
  @ContentChild(TemplateRef)
  bucketTemplate?: TemplateRef<MultiSelectFacetBucketContext<TData>>;

  /**
   * Emits on selection change and provides the selected values of the facet.
   */
  @Output() readonly changed = new EventEmitter<string[]>();

  /**
   * Emits on clear selection click
   */
  @Output() readonly clear = new EventEmitter<void>();

  selectionCount = 0;

  onSelectionChanged(values: string[]): void {
    this.selectionCount = values.length;
    this.changed.emit(values);
  }

  clearSelection(): void {
    this.selectionService.getSelectionState(this._selectionKey).setSelected({});
    this.changed.emit([]);

    if (this.trackInsightsEvents && this._insightsEventsConfig) {
      this.insightsEventsTrackingService?.trackEvent(this._insightsEventsConfig.filterReset);
    }

    this.clear.emit();
  }

  onExpandedChange(expanded: boolean): void {
    if (!this.trackInsightsEvents || !this._insightsEventsConfig) {
      return;
    }

    const filterExpansionEvent: InsightsEvent = expanded
      ? this._insightsEventsConfig.filterExpand
      : this._insightsEventsConfig.filterCollapse;
    this.insightsEventsTrackingService?.trackEvent(filterExpansionEvent);
  }

  onSearchTermChange(searchTerm: string): void {
    if (searchTerm && this.trackInsightsEvents && this._insightsEventsConfig) {
      this.insightsEventsTrackingService?.trackEvent(this._insightsEventsConfig.filterSearch);
    }
  }

  onValueSelect(valueLabel: string, checked: boolean): void {
    if (checked && this.trackInsightsEvents && this._insightsEventsConfig) {
      this.insightsEventsTrackingService?.trackEvent(this._insightsEventsConfig?.valueSelect, { value: valueLabel });
    }
  }

  private getFilterInsightsEventsConfig(filterName: string): MultiSelectFacetInsightsEventsConfig {
    return {
      filterExpand: insightsNamedEvent(filterName, InsightsBaseEvent.FILTER_EXPAND),
      filterCollapse: insightsNamedEvent(filterName, InsightsBaseEvent.FILTER_COLLAPSE),
      valueSelect: insightsNamedEvent(filterName, InsightsBaseEvent.FILTER_CHANGE),
      filterReset: insightsNamedEvent(filterName, InsightsBaseEvent.FILTER_RESET),
      filterSearch: insightsNamedEvent(filterName, InsightsBaseEvent.FILTER_SEARCH),
    };
  }
}
